var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card',[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-12 d-flex"},[_c('span',{staticClass:"h2 mr-auto text-secondary"},[_vm._v("Nouveau processus métier")]),_c('div',[_c('modal-actions',{on:{"close":function($event){return _vm.$router.go(-1)}}})],1)])]),_c('div',{staticClass:"shdow"},[_c('div',{staticClass:"d-flex flex-wrap justify-content-between align-items-center"},[_c('span',{staticClass:"h3 text-secondary"},[_vm._v("Informations générales")]),_c('div',[_c('b-button',{attrs:{"type":"submit","pill":"","disabled":_vm.submitingForm},on:{"click":_vm.createItem}},[(_vm.submitingForm)?_c('b-spinner',{staticClass:"text-white",attrs:{"small":"","label":"Loading..."}}):_vm._e(),_vm._v(" Confirmer")],1)],1)]),_c('hr',{staticClass:"mt-1 mb-3 bg-secondary"}),_c('div',{staticClass:"row p-0 m-0"},[_c('div',{staticClass:"col-12",staticStyle:{"flex":"1","margin-right":"20px"}},[_c('b-form',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 col-12"},[_c('div',{staticClass:"row my-3 align-items-center"},[_c('div',{staticClass:"col-md-4"},[_c('label',{staticClass:"font-weight-bold"},[_vm._v("Libellé processus:")])]),_c('div',{staticClass:"col-md-8"},[_c('b-form-input',{class:{
                      'is-invalid':
                        _vm.$v.newProcessus.name.$error &&
                        _vm.$v.newProcessus.name.$dirty,
                    },attrs:{"type":"text","placeholder":"Libellé"},model:{value:(_vm.newProcessus.name),callback:function ($$v) {_vm.$set(_vm.newProcessus, "name", $$v)},expression:"newProcessus.name"}}),(_vm.$v.newProcessus.name.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.newProcessus.name.required ? "Champ obligatoire" : !_vm.$v.newProcessus.name.minLength ? `Le champ doit contenir au moins ${_vm.$v.newProcessus.name.$params.minLength.min} caractères.` : "")+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row my-3 align-items-center"},[_c('div',{staticClass:"col-md-4"},[_c('label',{staticClass:"font-weight-bold"},[_vm._v("Description:")])]),_c('div',{staticClass:"col-md-8"},[_c('b-form-textarea',{attrs:{"type":"text","placeholder":"Description"},model:{value:(_vm.newProcessus.description),callback:function ($$v) {_vm.$set(_vm.newProcessus, "description", $$v)},expression:"newProcessus.description"}})],1)]),_c('div',{staticClass:"row my-3 align-items-center"},[_c('div',{staticClass:"col-md-4"},[_c('label',{staticClass:"font-weight-bold"},[_vm._v("Patrimoine:")])]),_c('div',{staticClass:"col-md-8",attrs:{"id":"add-page"}},[_c('div',{staticClass:"font-weight-normal p-0 form-control",class:{
                      'is-invalid':
                        _vm.$v.newProcessus.patrimoine.$error &&
                        _vm.$v.newProcessus.patrimoine.$dirty,
                    },staticStyle:{"padding":"0 !important","height":"fit-content"}},[_c('v-select',{staticStyle:{"background":"transparent"},attrs:{"placeholder":"Patrimoine","label":"text","reduce":({ value }) => value,"options":_vm.patrimoinesList},model:{value:(_vm.newProcessus.patrimoine),callback:function ($$v) {_vm.$set(_vm.newProcessus, "patrimoine", $$v)},expression:"newProcessus.patrimoine"}})],1),(_vm.$v.newProcessus.patrimoine.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.newProcessus.patrimoine.required ? "Champ obligatoire" : "")+" ")]):_vm._e()])]),_c('div',{staticClass:"row my-3 align-items-center"},[_c('div',{staticClass:"col-md-4"},[_c('label',{staticClass:"font-weight-bold"},[_vm._v("Capacité métier:")])]),_c('div',{staticClass:"col-md-8"},[_c('v-select',{attrs:{"label":"name","options":_vm.capaciteRatechements,"placeholder":"Capacité métier"},model:{value:(_vm.newProcessus.capaciteMetiers),callback:function ($$v) {_vm.$set(_vm.newProcessus, "capaciteMetiers", $$v)},expression:"newProcessus.capaciteMetiers"}})],1)]),_c('div',{staticClass:"row my-3 align-items-center"},[_c('div',{staticClass:"col-md-4"},[_c('label',{staticClass:"font-weight-bold"},[_vm._v("Processus métier:")])]),_c('div',{staticClass:"col-md-8"},[_c('v-select',{attrs:{"label":"name","track-by":"id","options":_vm.processusRatechements,"placeholder":"Processus métier"},on:{"input":() => {
                        _vm.newProcessus.niveau = _vm.newProcessus.parent ? 2 : 1;
                      }},model:{value:(_vm.newProcessus.parent),callback:function ($$v) {_vm.$set(_vm.newProcessus, "parent", $$v)},expression:"newProcessus.parent"}})],1)]),_c('div',{staticClass:"row my-3 align-items-center"},[_c('div',{staticClass:"col-md-4"},[_c('label',{staticClass:"font-weight-bold"},[_vm._v("Business Owner:")])]),_c('div',{staticClass:"col-md-8",attrs:{"id":"add-page"}},[_c('div',{staticClass:"font-weight-normal p-0 form-control",class:{
                      'is-invalid':
                        _vm.$v.newProcessus.businessOwner.$error &&
                        _vm.$v.newProcessus.businessOwner.$dirty,
                    },staticStyle:{"padding":"0 !important","height":"fit-content"}},[_c('v-select',{staticStyle:{"background":"transparent"},attrs:{"label":"text","reduce":(user) => user.value,"options":_vm.ownersList,"placeholder":"Business Owner"},model:{value:(_vm.newProcessus.businessOwner),callback:function ($$v) {_vm.$set(_vm.newProcessus, "businessOwner", $$v)},expression:"newProcessus.businessOwner"}})],1),(_vm.$v.newProcessus.businessOwner.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.newProcessus.businessOwner.required ? "Champ obligatoire" : "")+" ")]):_vm._e()])])]),_c('div',{staticClass:"col-md-6 col-12"},[_c('div',{staticClass:"row my-3 align-items-center"},[_c('div',{staticClass:"col-md-4"},[_c('label',{staticClass:"font-weight-bold"},[_vm._v("Organisation responsable:")])]),_c('div',{staticClass:"col-md-8",attrs:{"id":"add-page"}},[_c('div',{staticClass:"font-weight-normal p-0 form-control",class:{
                      'is-invalid':
                        _vm.$v.newProcessus.organisationResp.$error &&
                        _vm.$v.newProcessus.organisationResp.$dirty,
                    },staticStyle:{"padding":"0 !important","height":"fit-content"}},[_c('v-select',{staticStyle:{"background":"transparent"},attrs:{"label":"text","reduce":(organisation) => organisation.value,"options":_vm.organisationsList,"placeholder":"Organisation responsable"},model:{value:(_vm.newProcessus.organisationResp),callback:function ($$v) {_vm.$set(_vm.newProcessus, "organisationResp", $$v)},expression:"newProcessus.organisationResp"}})],1),(_vm.$v.newProcessus.organisationResp.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.newProcessus.organisationResp.required ? "champ obligatoire" : "")+" ")]):_vm._e()])]),_c('div',{staticClass:"row my-3 align-items-center"},[_c('div',{staticClass:"col-md-4"},[_c('label',{staticClass:"font-weight-bold"},[_vm._v("Date de création:")])]),_c('div',{staticClass:"col-md-8"},[_c('date-picker',{staticStyle:{"height":"44.22px !important"},attrs:{"disabled":"","format":"DD/MM/YYYY","value":new Date()}})],1)]),_c('div',{staticClass:"row my-3 align-items-center"},[_c('div',{staticClass:"col-md-4"},[_c('label',{staticClass:"font-weight-bold"},[_vm._v("Niveau:")])]),_c('div',{staticClass:"col-md-8"},[_c('b-form-input',{attrs:{"label":"number","placeholder":"Niveau","min":"1","disabled":""},model:{value:(_vm.newProcessus.niveau),callback:function ($$v) {_vm.$set(_vm.newProcessus, "niveau", $$v)},expression:"newProcessus.niveau"}})],1)]),_c('div',{staticClass:"row my-3 align-items-center"},[_c('div',{staticClass:"col-md-4"},[_c('label',{staticClass:"mb-0 font-weight-bold"},[_vm._v("Taux de remplissage:")])]),_c('div',{staticClass:"col-md-8"},[_c('b-progress',{attrs:{"max":"100","show-progress":"","height":"15px"}},[_c('b-progress-bar',{style:(`background: ${
                        _vm.tauxRemplissage > 20 && _vm.tauxRemplissage <= 70
                          ? '#f5f245'
                          : _vm.tauxRemplissage > 70
                          ? '#23d92c'
                          : '#f5ba45'
                      }`),attrs:{"value":_vm.tauxRemplissage,"label":`${_vm.tauxRemplissage}%`}})],1)],1)])])])],1)])]),_c('div',{staticClass:"mt-5"},[_c('b-tabs',{staticClass:"tab-solid tab-solid-primary mt-3"},[_c('b-tab',{attrs:{"title":"Processus rattachés"}},[_c('p',{staticClass:"text-secondary font-weight-bold"},[_vm._v(" Liste des processus mère ")]),_c('div',{staticClass:"col-12 p-0"},[_c('hr',{staticClass:"mt-1 mb-3 bg-secondary"})]),_c('job-process-processes',{attrs:{"processes":[]}})],1),_c('b-tab',{attrs:{"title":"Applications"}},[_c('job-process-application',{attrs:{"disabled":true,"applications":[]}})],1),_c('b-tab',{attrs:{"title":"Objets manipulés"}},[_c('job-process-business-object',{attrs:{"disabled":true,"object":[]}})],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }