<template>
  <div>
    <b-card>
      <div class="row mb-3">
        <div class="col-12 d-flex">
          <span class="h2 mr-auto text-secondary"
            >Nouveau processus métier</span
          >

          <div>
            <modal-actions @close="$router.go(-1)" />
          </div>
        </div>
      </div>

      <div class="shdow">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center"
        >
          <span class="h3 text-secondary">Informations générales</span>
          <div>
            <b-button
              @click="createItem"
              type="submit"
              pill
              :disabled="submitingForm"
            >
              <b-spinner
                small
                v-if="submitingForm"
                class="text-white"
                label="Loading..."
              ></b-spinner>
              Confirmer</b-button
            >
          </div>
        </div>
        <hr class="mt-1 mb-3 bg-secondary" />

        <div class="row p-0 m-0">
          <div class="col-12" style="flex: 1; margin-right: 20px">
            <b-form class="row">
              <div class="col-md-6 col-12">
                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label class="font-weight-bold">Libellé processus:</label>
                  </div>
                  <div class="col-md-8">
                    <b-form-input
                      type="text"
                      placeholder="Libellé"
                      :class="{
                        'is-invalid':
                          $v.newProcessus.name.$error &&
                          $v.newProcessus.name.$dirty,
                      }"
                      v-model="newProcessus.name"
                    ></b-form-input>
                    <!-- ERROR MESSAGES -->
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newProcessus.name.$dirty"
                    >
                      {{
                        !$v.newProcessus.name.required
                          ? "Champ obligatoire"
                          : !$v.newProcessus.name.minLength
                          ? `Le champ doit contenir au moins ${$v.newProcessus.name.$params.minLength.min} caractères.`
                          : ""
                      }}
                    </span>
                    <!--  -->
                  </div>
                </div>

                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label class="font-weight-bold">Description:</label>
                  </div>
                  <div class="col-md-8">
                    <b-form-textarea
                      type="text"
                      placeholder="Description"
                      v-model="newProcessus.description"
                    ></b-form-textarea>
                  </div>
                </div>

                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label class="font-weight-bold">Patrimoine:</label>
                  </div>
                  <div class="col-md-8" id="add-page">
                    <div
                      class="font-weight-normal p-0 form-control"
                      style="padding: 0 !important; height: fit-content"
                      :class="{
                        'is-invalid':
                          $v.newProcessus.patrimoine.$error &&
                          $v.newProcessus.patrimoine.$dirty,
                      }"
                    >
                      <v-select
                        v-model="newProcessus.patrimoine"
                        placeholder="Patrimoine"
                        label="text"
                        :reduce="({ value }) => value"
                        :options="patrimoinesList"
                        style="background: transparent"
                      />
                    </div>
                    <!-- ERROR MESSAGES -->
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newProcessus.patrimoine.$dirty"
                    >
                      {{
                        !$v.newProcessus.patrimoine.required
                          ? "Champ obligatoire"
                          : ""
                      }}
                    </span>
                    <!--  -->
                  </div>
                </div>
                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label class="font-weight-bold">Capacité métier:</label>
                  </div>

                  <div class="col-md-8">
                    <v-select
                      label="name"
                      v-model="newProcessus.capaciteMetiers"
                      :options="capaciteRatechements"
                      placeholder="Capacité métier"
                    ></v-select>
                  </div>
                </div>
                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label class="font-weight-bold">Processus métier:</label>
                  </div>
                  <div class="col-md-8">
                    <v-select
                      label="name"
                      track-by="id"
                      v-model="newProcessus.parent"
                      :options="processusRatechements"
                      placeholder="Processus métier"
                      @input="
                        () => {
                          newProcessus.niveau = newProcessus.parent ? 2 : 1;
                        }
                      "
                    ></v-select>
                  </div>
                </div>

                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label class="font-weight-bold">Business Owner:</label>
                  </div>
                  <div class="col-md-8" id="add-page">
                    <div
                      class="font-weight-normal p-0 form-control"
                      style="padding: 0 !important; height: fit-content"
                      :class="{
                        'is-invalid':
                          $v.newProcessus.businessOwner.$error &&
                          $v.newProcessus.businessOwner.$dirty,
                      }"
                    >
                      <v-select
                        label="text"
                        :reduce="(user) => user.value"
                        :options="ownersList"
                        placeholder="Business Owner"
                        v-model="newProcessus.businessOwner"
                        style="background: transparent"
                      />
                    </div>

                    <!-- ERROR MESSAGES -->
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newProcessus.businessOwner.$dirty"
                    >
                      {{
                        !$v.newProcessus.businessOwner.required
                          ? "Champ obligatoire"
                          : ""
                      }}
                    </span>
                    <!--  -->
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-12">
                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label class="font-weight-bold"
                      >Organisation responsable:</label
                    >
                  </div>
                  <div class="col-md-8" id="add-page">
                    <div
                      class="font-weight-normal p-0 form-control"
                      style="padding: 0 !important; height: fit-content"
                      :class="{
                        'is-invalid':
                          $v.newProcessus.organisationResp.$error &&
                          $v.newProcessus.organisationResp.$dirty,
                      }"
                    >
                      <v-select
                        label="text"
                        :reduce="(organisation) => organisation.value"
                        :options="organisationsList"
                        placeholder="Organisation responsable"
                        v-model="newProcessus.organisationResp"
                        style="background: transparent"
                      />
                    </div>

                    <!-- ERROR MESSAGES -->
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newProcessus.organisationResp.$dirty"
                    >
                      {{
                        !$v.newProcessus.organisationResp.required
                          ? "champ obligatoire"
                          : ""
                      }}
                    </span>
                    <!--  -->
                  </div>
                </div>
                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label class="font-weight-bold">Date de création:</label>
                  </div>
                  <div class="col-md-8">
                    <date-picker
                      style="height: 44.22px !important"
                      disabled
                      format="DD/MM/YYYY"
                      :value="new Date()"
                    ></date-picker>
                  </div>
                </div>

                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label class="font-weight-bold">Niveau:</label>
                  </div>
                  <div class="col-md-8">
                    <b-form-input
                      label="number"
                      placeholder="Niveau"
                      min="1"
                      disabled
                      v-model="newProcessus.niveau"
                    />
                  </div>
                </div>
                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label class="mb-0 font-weight-bold"
                      >Taux de remplissage:</label
                    >
                  </div>
                  <div class="col-md-8">
                    <b-progress class="" max="100" show-progress height="15px">
                      <b-progress-bar
                        :value="tauxRemplissage"
                        :label="`${tauxRemplissage}%`"
                        :style="`background: ${
                          tauxRemplissage > 20 && tauxRemplissage <= 70
                            ? '#f5f245'
                            : tauxRemplissage > 70
                            ? '#23d92c'
                            : '#f5ba45'
                        }`"
                      ></b-progress-bar>
                    </b-progress>
                  </div>
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <b-tabs class="tab-solid tab-solid-primary mt-3">
          <b-tab title="Processus rattachés">
            <p class="text-secondary font-weight-bold">
              Liste des processus mère
            </p>
            <div class="col-12 p-0">
              <hr class="mt-1 mb-3 bg-secondary" />
            </div>
            <job-process-processes :processes="[]" />
          </b-tab>

          <b-tab title="Applications">
            <job-process-application :disabled="true" :applications="[]" />
          </b-tab>

          <b-tab title="Objets manipulés">
            <job-process-business-object :disabled="true" :object="[]" />
          </b-tab>
        </b-tabs>
      </div>
    </b-card>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import JobProcessApplication from "./components/JobProcessApplication.vue";
import JobProcessBusinessObject from "./components/JobProcessBusinessObject.vue";
import JobProcessProcesses from "./components/JobProcessProcesses.vue";
import { required, minLength } from "vuelidate/lib/validators"; //validation
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
// import VueSlideBar from "vue-slide-bar";
import { USER_URL } from "@/helpers/constURL";

export default {
  components: {
    ModalActions,
    JobProcessProcesses,
    JobProcessApplication,
    JobProcessBusinessObject,
    // VueSlideBar,
  },
  data: () => ({
    submitingForm: false,
    newProcessus: {
      name: "",
      description: "",
      patrimoine: "",
      capaciteMetiers: null,
      businessOwner: null,
      parent: null,
      taux: 0,
      organisationResp: null,
      niveau: 1,
    },
  }),
  validations: {
    newProcessus: {
      name: {
        required,
        minLength: minLength(5),
      },
      patrimoine: {
        required,
      },
      businessOwner: {
        required,
      },
      organisationResp: {
        required,
      },
      niveau: {
        required,
      },
    },
  },
  methods: {
    createItem() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.submitingForm = true;
        this.$store
          .dispatch("processusMetier/createProcessusMetier", {
            name: this.newProcessus.name,
            description: this.newProcessus.description,
            organisationResp: this.newProcessus.organisationResp,
            patrimoine: this.newProcessus.patrimoine,
            businessOwner: this.newProcessus.businessOwner,
            taux: parseFloat(this.tauxRemplissage),
            isPrincipal: false,
            parent: this.newProcessus.parent
              ? this.newProcessus.parent["@id"]
              : null, // Processus parent
            capaciteMetiers: this.newProcessus.capaciteMetiers
              ? this.newProcessus.capaciteMetiers["@id"]
              : null, // Capacité metier
            niveau: parseInt(this.newProcessus.niveau),
          })
          .then(() => {
            this.submitingForm = false;
          });
      }
    },
  },
  created() {
    this.$store.dispatch("capaciteMetier/fetchAllCapaciteMetiers");
    this.$store.dispatch("processusMetier/fetchAllProcessusMetiers");
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store.dispatch("patrimoine/fetchAllPatrimoines");
  },
  computed: {
    ...mapGetters("capaciteMetier", ["CAPACITE_METIERS"]),
    ...mapGetters("processusMetier", ["PROCESSUS_METIER", "PROCESSUS_METIERS"]),
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("organisation", ["ORGANISATIONS"]),
    ...mapGetters("patrimoine", ["PATRIMOINES"]),

    processusRatechements() {
      return this.newProcessus.capaciteMetiers
        ? this.newProcessus.capaciteMetiers.processus.filter(
            (process) => process.niveau === 1
          )
        : [];
    },
    capaciteRatechements() {
      return this.CAPACITE_METIERS.filter((capacite) => capacite.niveau === 1);
    },

    patrimoinesList() {
      return this.PATRIMOINES.map((patrimoine) => ({
        text: patrimoine.name,
        value: patrimoine["@id"],
      }));
    },
    ownersList() {
      return this.RESPONSABLES.map((responsable) => ({
        text: `${responsable.firstname} ${responsable.lastname}`,
        value: `/api/${USER_URL}/${responsable.id}`,
      }));
    },
    organisationsList() {
      return this.ORGANISATIONS.map((organisation) => ({
        text: organisation.libelle,
        value: organisation["@id"],
      }));
    },
    tauxRemplissage() {
      let i = 1;
      if (this.newProcessus.name) i++;
      if (this.newProcessus.description) i++;
      if (this.newProcessus.patrimoine) i++;
      if (this.newProcessus.capaciteMetiers) i++;
      if (this.newProcessus.parent) i++;
      if (this.newProcessus.businessOwner) i++;
      if (this.newProcessus.organisationResp) i++;
      return ((i * 100) / 11).toFixed(2);
    },
  },
  watch: {
    PROCESSUS_METIER() {
      this.submitingForm = false;
      Swal.fire({
        title: "Le processus métier est bien créé!",
        type: "success",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.value) {
          this.$router.push({
            name: "show-job-process",
            params: {
              slug: this.PROCESSUS_METIER.slug,
              id: this.PROCESSUS_METIER.id,
              fromCreation: true,
            },
          });
        }
      });
    },
  },
};
</script>

<style></style>
